<template>
    <!-- 新增上传机场 -->
    <el-dialog class="importDialog" title="导入机场" :visible.sync="importDialog" width="450px"
        :modal-append-to-body="false" :close-on-click-modal="false" top="20vh" :before-close="handleCloseImport">
        <div class="content">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto" class="demo-ruleForm">
                <el-form-item :label="$t('airportManager.coordinateSystem') + '：'">
                    <el-input v-model="ruleForm.coordinateSystem" disabled size="small" style="width: 100%;" />
                </el-form-item>
                <el-form-item :label="$t('airportManager.fileName') + '：'" prop="fileName">
                    <div class="choose-file">
                        <div class="file-name">
                            <el-input v-model="ruleForm.fileName" style="width: 100%;" size="small" 
                                disabled :placeholder="$t('airportManager.fileNamePlaceholder')" />
                        </div>
                        <div class="choose-btn">
                            <el-button type="primary" size="small" @click="chooseFiles">{{$t('chooseFile')}}</el-button>
                            <input style="display:none" type="file" ref="airport-upload" 
                                :accept="acceptType" @change="handleFiles($event)" />
                        </div>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="handleCloseImport">{{$t('cancelText')}}</el-button>
            <el-button size="small" type="primary" :loading="loading" @click="submitImport">{{$t('submitText')}}</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    name: 'add-airport',
    data() {
        return {
            loading: false,
            importDialog: false,
            ruleForm: {
                coordinateSystem: 'WGS84',
                fileName: '',
                fileList: []
            },
            acceptType: '.kml',
            rules: {
                fileName: [
                    {required: true, message: this.$t('airportManager.fileName'), trigger: 'change'}
                ]
            }
        }
    },
    methods: {
        chooseFiles() {
            this.$refs['airport-upload'].value = '';
            this.$refs['airport-upload'].click();
        },
        handleFiles(e) {
            this.ruleForm.fileName = '';
            this.ruleForm.fileList = [];
            let files = e.target.files;
            if (!files) return;
            for (let i = 0; i < files.length; i++) {
                this.ruleForm.fileName = i === files.length - 1 ? `${this.ruleForm.fileName}${files[i].name}` 
                    : `${this.ruleForm.fileName}${files[i].name}，`;
                this.ruleForm.fileList.push(files[i]);
            }
        },
        handleCloseImport() {
            this.importDialog = false;
            this.$refs['ruleForm'].resetFields();
            this.ruleForm = {
                coordinateSystem: 'WGS84',
                fileName: '',
                fileList: []
            };
        },
        handleOpenImport() {
            this.importDialog = true;
            this.ruleForm = {
                coordinateSystem: 'WGS84',
                fileName: '',
                fileList: []
            };
        },
        submitImport() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid && this.ruleForm.fileList && this.ruleForm.fileList.length) {
                    this.loading = true;
                    this.$emit('upLoadEmit', {data: this.ruleForm, callback: this.handleCloseImport});
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.importDialog {
    ::v-deep .el-dialog {
        background-color: rgba(30, 34, 42, 1);
        .el-dialog__header {
            .el-dialog__title {
                font-size: 16px;
                color: #FFFFFF;
            }
            .el-dialog__headerbtn .el-dialog__close {
                color: #ffffff;
            }
        }
        .el-dialog__body {
            padding: 15px 20px;
            .content {
                .el-form-item__label {
                    color: #ffffff;
                }
                .el-input__inner {
                    font-size: 14px;
                    color: #ffffff;
                    background-color: transparent;
                    &::placeholder {
                        color:  rgba(153, 153, 153, 0.79);
                    }
                }
                .choose-file {
                    display: flex;
                    align-items: center;
                    .file-name {
                        flex: 1;
                    }
                    .choose-btn {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
</style>