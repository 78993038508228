<template>
    <!-- 机场管理首页 -->
    <div class="container normal mission-center-box">
        <AirportList v-if="showMap" />
        <CusiumMap ref="airportCusiumMap" class="mission-center-lastItem" v-if="showMap" />
    </div>
</template>

<script>
import AirportList from './operationBar/airportList';
import CusiumMap from "@/components/cesium/index";

export default {
    name: 'airport-manager',
    components: {CusiumMap, AirportList},
    computed: {
        showMap() {
            let secondLevel = this.$route.matched[1];
            return secondLevel.name === 'airport-manager';
        }
    }
}
</script>

<style lang="scss" scoped>
.mission-center-box {
    width: 100%;
    height: 100%;
    display: flex;
    .mission-center-lastItem{
        flex-grow: 1;
        overflow: hidden;
    }
}
</style>