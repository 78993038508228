<template>
    <!-- 机场列表 -->
    <div class="airport-list">
        <div class="airport-list_top">
            <div class="title">
                <div class="title-text">
                    <span>{{$t('airportManager.moduleTitle')}}</span>
                </div>
                <div class="download">
                    <!-- <img class="icon-upload" src="/img/taskCenter/labelPage/上传.png" @click="openUploadFile" /> -->
                    <!-- <img class="icon-download" src="/img/taskCenter/labelPage/下载.svg" /> -->
                </div>
            </div>
            <div class="search">
                <el-input @keyup.enter.native="handleSearch" :placeholder="$t('airportManager.moduleSearch')" size="mini" 
                    clearable v-model="searchForm.searchVal">
                    <i slot="suffix" class="search-icon el-input__icon el-icon-search"></i>
                </el-input>
            </div>
        </div>
        <div class="airport-list_cent">
            <div class="no-data" v-if="!airportData || !airportData.length">
                <i class="el-icon-folder-delete" />
                <p>暂无数据...</p>
            </div>
            <div class="airport-list-item" :class="{'active': chooseAirportItem.id === item.id}" 
                v-for="(item, index) in airportData" :key="item.id + index" @click="chooseAirport(item, 'selected')">
                <div class="item-label">
                    <img class="point-img" v-if="item.content.type === 'Point'" src="/img/map/mark_manager.png" alt="">
                    <img class="polygon-img" v-else-if="item.content.type === 'Polygon'" src="/img/map/MarkPolygon.png" alt="">
                    <span>{{item.airportName}}</span>
                </div>
                <div class="item-icon">
                    <i class="el-icon-aim" @click.stop="locationItem(item)" />
                    <!-- <img class="icon-download" src="/img/taskCenter/labelPage/下载.svg" alt=""> -->
                    <i class="el-icon-delete" @click.stop="deleteItemData(item)" />
                </div>
            </div>
        </div>
        <!-- 新增上传机场弹窗 -->
        <AddAirport ref="airportUploadFile" @upLoadEmit="uploadFiles" />
    </div>
</template>

<script>
import AddAirport from './components/addAirport';
import {getAirportList, deleteAirportItem} from '@/api/airportManager/index';

export default {
    name: 'airport-list',
    components: {AddAirport},
    data() {
        return {
            searchForm: {searchVal: ''},
            chooseAirportItem: {},
            airportData: []
        }
    },
    created() {
        this.searchForm = {searchVal: ''};
        this.chooseAirportItem = {};
    },
    mounted() {
        this.$EventBus.$emit('close-airport-plan');
        this.getAirportData();
        this.$EventBus.$off('airport-left-update');
        this.$EventBus.$on('airport-left-update', data => {
            this.chooseAirportItem = data;
            this.getAirportData(true);
        });
        this.$EventBus.$off('airport-delete');
        this.$EventBus.$on('airport-delete', data => {
            this.deleteItemData(data);
        });
        this.$EventBus.$off('choose-airport-data');
        this.$EventBus.$on('choose-airport-data', data => {
            this.chooseAirport(data, 'noToFly');
        });
        this.$EventBus.$off('clear-choose-airport-data');
        this.$EventBus.$on('clear-choose-airport-data', () => {
            this.chooseAirportItem = {};
        });
    },
    methods: {
        getAirportData(noDrawing) {
            let params = {airportName: this.searchForm.searchVal};
            getAirportList(params).then(res => {
                if (res.data.code == 200) {
                    this.airportData = res.data.data || [];
                    !noDrawing && this.$EventBus.$emit('draw-airport-data', this.airportData);
                }
            });
        },
        handleSearch() {
            this.getAirportData();
        },
        chooseAirport(data, type) {
            if (this.chooseAirportItem.id === data.id) return;
            this.chooseAirportItem = data;
            if (type !== 'noToFly') this.locationItem(data);
            (type && type !== 'noToFly') && this.$EventBus.$emit('select-airport-by-id', data.id);
        },
        locationItem(data) {
            this.$EventBus.$emit('airport-location', data);
        },
        deleteItemData(data) {
            this.$confirm('此操作将永久删除不可恢复, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteAirportItem({ids: data.id}).then(res => {
                    if (res.data.code == 200) {
                        this.$message({type: 'success', message: '删除成功!'});
                        let item = this.airportData.find(t => t.id === data.id);
                        if (item) {
                            let index = this.airportData.indexOf(item);
                            if (index !== -1) {
                                this.airportData.splice(index, 1);
                            }
                        }
                        this.$EventBus.$emit('delete-airport-success', data);
                    }
                });
            });
        },

        openUploadFile() {
            this.$refs.airportUploadFile.handleOpenImport();
        },
        uploadFiles(data) {
            data.callback && data.callback();
            this.$refs.airportUploadFile.loading = false;
        }
    },
    beforeDestroy() {
        this.searchForm = {searchVal: ''};
        this.chooseAirportItem = {};
        this.$EventBus.$emit('close-airport-plan');
    }
}
</script>

<style lang="scss" scoped>
.airport-list {
    width: 342px;
    min-width: 290px;
    border-top: 1px solid #030303;
    background-color: #1e222a;
    height: 100%;
    display: flex;
    flex-direction: column;
    .airport-list_top {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #030303;
        padding: 15px 10px;
        .title {
            display: flex;
            align-items: center;
            .title-text {
                display: flex;
                align-items: center;
                flex: 1;
                font-size: 14px;
                color: #FFFFFF;
            }
            .icon-upload {
                width: 20px;
                height: 16px;
                margin-left: 8px;
                cursor: pointer;
            }
            .icon-download {
                width: 19px;
                height: 14px;
                margin-left: 8px;
                cursor: pointer;
            }
        }
        .search {
            margin-top: 15px;
            ::v-deep .el-input__inner {
                background-color: #030303;
                border-color: #535353;
                border-radius: 2px;
                color: #FFFFFF;
                font-size: 14px;
            }
        }
    }
    .airport-list_cent {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-bottom: 5px;
        overflow-y: auto;
        .no-data {
            text-align: center;
            margin-top: 10px;
            color: #76787D;
            font-size: 14px;
            .el-icon-folder-delete {
                font-size: 25px;
            }
        }
        .airport-list-item {
            display: flex;
            align-items: center;
            margin-top: 7px;
            padding: 10px;
            cursor: pointer;
            &:first-child {
                margin-top: 0;
            }
            &.active {
                background-color: #3573FF33;
            }
            .item-label {
                display: flex;
                align-items: center;
                flex: 1;
                font-size: 14px;
                font-weight: 700;
                font-style: normal;
                color: #FFFFFF;
                .point-img {
                    width: 16px;
                    height: 19px;
                    margin-right: 5px;
                }
                .polygon-img {
                    width: 19px;
                    height: 19px;
                    margin-right: 5px;
                }
            }
            .item-icon {
                margin-left: 5px;
                cursor: pointer;
                .el-icon-aim {
                    font-size: 17px;
                    color: #FFFFFF;
                    margin-left: 7px;
                }
                .icon-download {
                    width: 19px;
                    height: 14px;
                    margin-left: 7px;
                }
                .el-icon-delete {
                    font-size: 17px;
                    color: #FFFFFF;
                    margin-left: 7px;
                }
            }
        }
    }
}
</style>